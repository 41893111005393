import Page from '../../classes/Page'

export default class Documents extends Page {
  constructor() {
    super({
      id: 'documents',
      element: '#d',
    })
  }

  create() {
    super.create()
  }
}
