import Page from '../../classes/Page'

export default class Equipes extends Page {
    constructor() {
        super({
            id: 'equipes',
            element: '#e',
        })
    }

    create() {
        super.create()
    }
}
