import Page from '../../classes/Page'

export default class Equipes extends Page {
    constructor() {
        super({
            id: 'staff',
            element: '#s',
        })
    }

    create() {
        super.create()
    }
}
