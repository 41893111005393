import Page from '../../classes/Page'

export default class Partenaires extends Page {
  constructor() {
    super({
      id: 'partenaires',
      element: '#p',
    })
  }

  create() {
    super.create()
  }
}
