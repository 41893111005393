import Page from '../../classes/Page'

export default class Contact extends Page {
  constructor() {
    super({
      id: 'contact',
      element: '#cont',
    })
  }

  create() {
    super.create()
  }
}
