import Page from '../../classes/Page'

export default class Historique extends Page {
    constructor() {
        super({
            id: 'historique',
            element: '#h',
        })
    }

    create() {
        super.create()
    }
}
