import Page from 'classes/Page'

export default class Actualites extends Page {
  constructor() {
    super({
      id: 'actualites',
      element: '#ac',
    })
  }

  create() {
    super.create()
  }
}
